//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import EPointTransferDialog from '@/components/EPointTransferDialog.vue'

export default {
  components: {
    MbscDatePicker,
    EPointTransferDialog
  },
  data () {
    return {
      init: false,
      epoint_transfer_dialog_show: false,
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 't_date', align: 'center', label: 'วันที่', field: 't_date'},
        { name: 't_time', align: 'center', label: 'เวลา', field: 't_time'},
        { name: 't_to_mcode', align: 'center', label: 'รหัสที่รับโอน', field: 't_to_mcode' },
        { name: 't_to_name_t', align: 'left', label: 'ชื่อผู้รับโอน', field: 't_to_name_t' },
        { name: 't_epoint', align: 'right', label: 'ยอดโอน e-Point', field: 't_epoint' },
        { name: 't_remark', align: 'left', label: 'บันทึกความจำ', field: 't_remark' }
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      openTransferDialog: function() {
        this.epoint_transfer_dialog_show = true;
      },
      epoint_transfer_dialog_hide () {
        this.epoint_transfer_dialog_show = false;
        //console.log("hide set epoint_transfer_dialog_show = " + this.epoint_transfer_dialog_show);
      },
      exportPDF: function() {
          let self = this;
          openURL(self.url_api + '../pdf/ePoint.GetBalance.ByDate.PDF.aspx');    
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      //Bonus/Report.Daily.Bonus.Getlastdate.aspx
      fetchBonusDate: function () {
        //console.log("fetch bonus date");
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);

        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          //let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = self.session_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      editMode() {
        //console.log("click");
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('mcode', self.session_mcode);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/ePoint.Transfer.ByDate.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.init = true;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      /*
      this.$q.loading.show();
      let self = this;
      setTimeout( function() { 
        self.getData();
      }, 1000);
      */
     this.fetchBonusDate();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }  
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }  
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","โอน e-Point");
      this.$q.loading.show();
      //console.log('Component created.');
  }    
}   
