//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { get } from "vuex-pathify";
import UplineListDialog from '@/components/UplineListDialog.vue'
import RegistrationBillDialog from '@/components/RegistrationBillDialog.vue'

export default {
  components: {
    UplineListDialog,
    RegistrationBillDialog,
  },
  name: "RegistrationForm",
  props: ["mcode", "show", "init_up_code", "init_side"],
  data () {
    return {
        member_age_valid: true,
        new_registration: false,
        init_up_code_flag: false,
        email_valid: false,
        submit_btn_disable: false,
        current_mcode: "",
        add_btn_panel: "",
        insert_btn_panel: "hidden",
        isPwd: true,
        bill_dialog_show: false,
        idaddress_district_dialog_show: false,
        address_district_dialog_show: false,
        scode_dialog_show: false,
        spcode_dialog_show: false,
        upcode_dialog_show: false,
        member_password_dialog_show: false,
        new_member: {number:"", prodcode:"", prodid:"", mcode:"",position:"",rank:"",name_t:"",mdate:"",id_card:"",up_code:"",up_name:"",sp_code:"",sp_name:"",side:"L" },
        member: {number:"", prodcode:"", prodid:"", mcode:"",position:"",rank:"",name_t:"",mdate:"",id_card:"",up_code:"",up_name:"",sp_code:"",sp_name:"",side:"L" },
        //member: null,
        readonly_color: "grey-2",
        input_editable_color: "grey-2",
        side_toggle_color: "grey-8",
        width: 400,
        splitterAddressPanel: 50, // start at 50%
        readonly: true,
        valid_id_card: true,
        valid_upline: true,
        valid_sponsor: true,
        valid_name_t: true,
    }
  },
  methods: {
    confirm_post() {
      let valid_all = true;
     
      if ( !this.compute_valid_upline ) 
      {
          valid_all = false;
      } 
      //alert("valid all : " + valid_all);
      if (valid_all) {
          this.$q.dialog({
            title: "ยืนยันการสร้างรหัสสมาชิก",
            message: " เลขบัตรประชาชน : <b>" + this.member.id_card + "</b><br>" +
                    " ชื่อ-นามสกุล : <b>" + this.member.name_t + "</b></br>" + 
                    " ตำแหน่ง : <b>" + this.member.rank + "</b></br>" + 
                    " รหัสผู้แนะนำ : <b>" + this.member.sp_code + " - " + this.member.sp_name + "</b></br>" + 
                    " รหัสอัพไลน์ : <b>" + this.member.up_code + " - " + this.member.up_name + "</b>&nbsp;ด้าน :<b>" + this.side_th + "</b>",
            persistent: true,
            html: true,
            class: "app-font",
            style: "background-color:#FFFFFF; color:#000000;",
            ok: {
              label: "ตกลง",
              color: 'positive'
            },
            cancel: {
              label: "ยกเลิก"
            },
          }).onOk(() => {
            this.post_member();
            // console.log('>>>> OK')
          }).onOk(() => {
            // console.log('>>>> second OK catcher')
          }).onCancel(() => {
            this.submit_btn_disable = false;
            // console.log('>>>> Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          });

      } else {
          
          let error_msg = "";
          let error_count = 0;
        
          if (!this.compute_valid_upline) {
            error_msg = error_msg + " รหัสอัพไลน์";
            error_count = error_count + 1;
          }
          //alert("error_msg : " + error_msg);
          let self = this;
          let err_txt;
          if (error_count > 1) {
            err_txt = self.replaceAll(error_msg.trim()," ",", ");
          } else {
            err_txt = error_msg;
          }
          self.triggerNegative("ตรวจสอบข้อมูล " + err_txt);
          //this.triggerNegative(error_msg);
      }
     
    },
    post_member() {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('position', self.member.position);
        params.append('id_card', self.member.id_card);
        params.append('name_t', self.member.name_t);
        params.append('sp_code', self.member.sp_code);
        params.append('sp_name', self.member.sp_name);
        params.append('up_code', self.member.up_code);
        params.append('up_name', self.member.up_name);
        params.append('side', self.member.side);
        params.append('number', self.member.number);
        params.append('prodcode', self.member.prodcode);
        params.append('prodID', self.member.prodid);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Member.Insert.Code.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('insert_status')[0].textContent;
          let result_mcode = responseDoc.getElementsByTagName('mcode')[0].textContent;
          let result_txt;
          if (result != "error") {
            switch(result_mcode) {
              case "upcode":
                result_txt = "รหัสอัพไลน์ไม่สามารถต่อได้  กรุณาเลือกใหม่อีกครั้ง...";
                self.triggerNegative(result_txt);
                break;
              case "quse":
                result_txt = "ชุดสมัครไม่เพียงพอในการสมัครสายงาน...";
                self.triggerNegative(result_txt);
                break;
              case "mcode":
                result_txt = "รหัสสมาชิกมีผู้อื่นใช้งานแล้ว...";
                self.triggerNegative(result_txt);
                break;
              case "false":
                result_txt = "ระบบเกิดข้อผิดพลาดไม่สามารถสร้างสมาชิกได้...";
                self.triggerNegative(result_txt);
                break;
              case "error":
                result_txt = "ข้อมูลที่บันทึกไม่ถูกต้อง...";
                self.triggerNegative(result_txt);
                break;    
              default:
                result_txt = "บันทึกข้อมูลเรียบร้อย รหัสสมาชิก : "+ result_mcode;
                self.triggerPositive(result_txt);
                self.view_form();
                self.$emit("inserted",result_mcode);
            }
            self.$q.loading.hide();
          } else {
            result_txt = "ระบบไม่สามารถบันทึกข้อมูลได้";
            self.triggerNegative(result_txt);
            self.$q.loading.hide();
          }
          self.submit_btn_disable = false;
        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
          console.log("Error :" + error);
          self.$q.loading.hide();
          self.submit_btn_disable = false;
        });
    },
    triggerPositive (msg_text) {
      this.$q.notify({
        type: 'positive',
        progress: true,
        message: msg_text,
        classes:'app-font'
      });
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        message: msg_text,
        classes:'app-font'
      })
    },    
    cancel_registration () {
      this.new_registration = true;
      this.view_form();
    },
    registration () {
      this.new_registration = true;
      this.popupBillDialog();
    },
    popupBillDialog() {
      this.$q.loading.show();
      let self = this;
      setTimeout(() => {
        self.$refs.bill_dialog.fetchDataList();
        self.$q.loading.hide();
        self.bill_dialog_show = true;
      }, 500);
    },
    init_form () {
      if (this.init_up_code != "" && this.new_registration == false) {
        //alert("this.init_up_code : " + this.init_up_code );
        this.init_up_code_flag = true;
        this.member.up_code = this.init_up_code ;
        this.member.side = this.init_side;
        //this.member.sp_code = this.session_mcode;
        /*
        this.member.sp_code = this.session_mcode;
        this.fetchSponsor();
        */
        //console.log("init_up_code : " + this.init_up_code);
        //alert("init_up_code : " + this.init_up_code);
        /*
        setTimeout( function() { 
            this.fetchUpline();        
        }, 1000);
        */
      } else {
        this.member.up_code = "";
        this.member.up_name = "";
      }
      this.valid_upline = false;
      this.valid_sponsor = false;
      this.readonly = false;
      this.input_editable_color = "yellow-1";
      this.side_toggle_color = "primary";
      this.insert_btn_panel = "";
      this.add_btn_panel = "hidden";
      this.current_mcode = "";
      this.$emit("mode", "edit");
      if (this.init_up_code_flag) {
        this.fetchUpline();
        //this.fetchSponsor();
        this.init_up_code_flag = false;
      }
    },
    view_form () {
      this.readonly = true;
      this.insert_btn_panel = "hidden";
      this.add_btn_panel = "";
      this.current_mcode = this.mcode;
      this.input_editable_color = "grey-2";
      this.side_toggle_color = "grey-8";
      this.$emit("mode", "view");
      this.fetchData();
    },
    getSelectedBill (selected_item, rank, position) {
      /*
      let msg = "";
      let number = selected_item.number;
      msg = msg + " number : " + number + "\n";
      let prodcode = selected_item.prodcode;
      msg = msg + " prodcode : " + prodcode + "\n";
      let prodid = selected_item.prodid;
      msg = msg + " prodid : " + prodid;
      alert(msg);
      */
      this.member = this.new_member;
      this.member.mdate = this.session_date;
      this.member.number = selected_item.number;
      this.member.prodcode = selected_item.prodcode;
      this.member.prodid = selected_item.prodid;
      this.member.sp_code = this.session_mcode;
      this.member.sp_name = this.session_mname;
      this.member.id_card = selected_item.id_card;
      this.member.name_t = selected_item.name_t;
      this.member.position = position;
      this.member.rank = rank;
      this.init_form();
    },
    getSelectedSponsor (selected_item) {
      this.member.sp_code = selected_item.mcode;
      //this.member.sp_name = selected_item.name_t;
      this.fetchSponsor();
    },
    getSelectedUpline (selected_item) {
      this.member.up_code = selected_item.mcode;
      //this.member.up_name = selected_item.name_t;
      this.fetchUpline();
    },
    bill_dialog_hide () {
      this.bill_dialog_show = false;
    },
    spcode_dialog_hide () {
      this.spcode_dialog_show = false;
      //console.log("hide set spcode_dialog_show = " + this.spcode_dialog_show);
    },
    upcode_dialog_hide () {
      this.upcode_dialog_show = false;
      //console.log("hide set upcode_dialog_show = " + this.upcode_dialog_show);
    },
    fetchSponsor: function () {
          //console.log("fetchSponsor");
          if (this.member.sp_code.length < this.app_mcode_length) {
            this.member.sp_code = this.paddingCode(this.member.sp_code);
          }
          let self = this;
          if (self.member.sp_code.length == self.app_mcode_length && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("mcode", this.session_mcode);
              params.append("sp_code", this.member.sp_code);
              params.append("agent", this.session_agent);
              self.axios({
                method: "post",
                headers: {
                  'Authorization': self.jwt_token
                },    
                timeout: 0, // Let's say you want to wait at least unlimit mins
                url: self.url_api + "MemberClient/Member.GetSpcodeInfo.JSON.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let result_name = responseDoc.getElementsByTagName('name_t')[0].textContent;
                  if (result_name.length > 0) {
                    self.valid_sponsor = true;
                    self.member.sp_name = result_name;
                    self.triggerPositive("รหัสแนะนำถูกต้อง");
                    //self.fetchUpline();
                  } else {
                    self.valid_sponsor = false;
                    self.member.sp_name = "";
                    self.triggerNegative("รหัสแนะนำไม่ถูกต้อง");
                  }
                  /*
                  if (event.result.member.sp_code.toString() != "000000000") {
                    sp_code_txt.text = event.result.member.sp_code.toString();
                    sp_code_txt.editable = false;
                    sp_code_txt.styleName = "GeneralTextInputReadOnly";
                    Validate_SpCode();
                  } 
                  */
                  self.$q.loading.hide();
                  //console.log(self.member.birthday.substring(6,10) + "-" + self.member.birthday.substring(3,5) + "-" + self.member.birthday.substring(0,2))
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.valid_sponsor = false;
                  self.member.sp_name = "";
                  self.triggerNegative("รหัสแนะนำไม่ถูกต้อง");
                  self.$q.loading.hide();
              });
              self.$q.loading.hide();
          }
    }, 
    fetchUpline: function () {
          if (this.member.up_code.length < this.app_mcode_length) {
            this.member.up_code = this.paddingCode(this.member.up_code);
          }
          //alert("fetch upline");
          let self = this;
          if (self.member.up_code.length == self.app_mcode_length && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("mcode", this.session_mcode);
              params.append("up_code", this.member.up_code);
              params.append("side", this.member.side);
              params.append("agent", this.session_agent);
              self.axios({
                method: "post",
                headers: {
                  'Authorization': self.jwt_token
                },                
                timeout: 0, // Let's say you want to wait at least unlimit mins
                url: self.url_api + "MemberClient/Member.GetUpcodeInfo.JSON.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let result_side = responseDoc.getElementsByTagName('check_upcode')[0].textContent;
                  let result_name = responseDoc.getElementsByTagName('name_t')[0].textContent;
                  //console.log("result_side :" + result_side.toString());
                  if (result_name.length > 0 && result_side == "PASS") {
                    self.valid_upline = true;
                    self.member.up_name = result_name;
                    self.triggerPositive("รหัสอัพไลน์ถูกต้อง");
                  } else {
                    self.valid_upline = false;
                    self.member.up_name = "";
                    if (result_side == "OUTER")
                    {
                      self.triggerNegative("ข้ามสายงาน");
                      self.valid_upline = false;
                    }
                    
                    if (result_side == "FULL")
                    {
                      self.triggerNegative("สายงานไม่ว่าง");
                      self.valid_upline = false;
                    }
                  }
                  /*
                  if (event.result.member.sp_code.toString() != "000000000") {
                    sp_code_txt.text = event.result.member.sp_code.toString();
                    sp_code_txt.editable = false;
                    sp_code_txt.styleName = "GeneralTextInputReadOnly";
                    Validate_SpCode();
                  } 
                  */
                  self.$q.loading.hide();
                  //console.log(self.member.birthday.substring(6,10) + "-" + self.member.birthday.substring(3,5) + "-" + self.member.birthday.substring(0,2))
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.$q.loading.hide();
              });
              self.$q.loading.hide();
          }
    },
    fetchData: function () {
        if (this.current_mcode != "") {
            let self = this;
            self.$q.loading.show();
            const params = new URLSearchParams();
            params.append("mcode", this.current_mcode);
            //console.log("process fetch data mcode:"+ this.mcode);
            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },
              timeout: 0, // Let's say you want to wait at least unlimit mins
              url: self.url_api + "MemberClient/Member.View.JSON.aspx",
              data: params
            })
            .then(function (response) {
                let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
                if (record_count > 0) {
                    self.member.number = responseDoc.getElementsByTagName('number')[0].textContent;
                    self.member.prodid = responseDoc.getElementsByTagName('prodid')[0].textContent;
                    self.member.prodcode = responseDoc.getElementsByTagName('prodcode')[0].textContent;
                    self.member.id_card = responseDoc.getElementsByTagName('id_card')[0].textContent;
                    self.member.rank = responseDoc.getElementsByTagName('rank')[0].textContent;
                    self.member.mdate = responseDoc.getElementsByTagName('mdate')[0].textContent;
                    self.member.mcode = responseDoc.getElementsByTagName('mcode')[0].textContent;
                    self.member.name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
                    self.member.sp_code = responseDoc.getElementsByTagName('sp_code')[0].textContent;
                    self.member.sp_name = responseDoc.getElementsByTagName('sp_name')[0].textContent;
                    self.member.up_code = responseDoc.getElementsByTagName('up_code')[0].textContent;
                    self.member.up_name = responseDoc.getElementsByTagName('up_name')[0].textContent;
                    self.member.side = responseDoc.getElementsByTagName('side')[0].textContent;
                } else {
                    self.member = self.new_member;
                }
                if (self.init_up_code.length == self.app_mcode_length && self.new_registration == false) {
                    self.popupBillDialog();
                }
                self.$q.loading.hide();
            })
            .catch(function (error) {
                console.log("Error :" + error);
                self.$q.loading.hide();
            });
        } else {
          let self = this;
          if (self.init_up_code.length == self.app_mcode_length && self.new_registration == false) {
              self.popupBillDialog();
          }
        }
    },
  },
  computed: {
      url_api: get("URL_API_PATH"),
      jwt_token: get('JWT_TOKEN'),
      editableColor: get("EDITABLE_COLOR"),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mname: get('SESSION_MCODE_NAME'),
      session_date: get('SESSION_DATE'),
      app_mcode_length: get('APP_MCODE_LENGTH'),
      session_agent: get('SESSION_AGENT'),
      session_scode: get('SESSION_SCODE'),
      side_th () {
        if (this.member.side == "L") {
          return "ซ้าย";
        } else {
          return "ขวา";
        }
      },
      compute_valid_sponsor() {
        if (this.valid_sponsor && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_upline() {
        if (this.valid_upline && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      upcode_isValid() {
        if (this.member.up_code.length == this.app_mcode_length && this.valid_upline || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      spcode_isValid() {
        if (this.member.sp_code.length == this.app_mcode_length && this.valid_sponsor || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
  },
  watch: {
    mcode: function () {
      //console.log("fetchData mcode:" + this.mcode);
      //if (this.new_registration == true) {
        this.current_mcode = this.mcode;
        this.fetchData();
      //}
    },
    /*
    "member.sp_code": function() {
      if (this.member.sp_code.length < this.app_mcode_length) {
        this.member.sp_name = "";
        this.valid_sponsor = false;
      }
    },
    */
    "member.up_code": function() {
      if (this.member.up_code.length < this.app_mcode_length) {
        this.member.up_name = "";
        this.valid_upline = false;
      } else {
        if (this.member.up_code.length == this.app_mcode_length) {
          this.fetchUpline();
        }
      }
    },
    "member.side": function() {
      if (!this.readonly && this.new_registration == true) {
          this.fetchUpline();
      }
    } 
    
    
  },
  mounted:function () {
    this.current_mcode = this.mcode;
    
    let self = this;
    if (self.init_up_code.length == self.app_mcode_length) {
        setTimeout( function() {
          self.$q.loading.hide();
          self.popupBillDialog();
        }, 1000);
    } else {
        self.$q.loading.hide();
    }
    
  },
  created () {
      //console.log('Component created.');
      this.$q.loading.show();
  }    
}       
