//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'AddressListDialog',
  props: ["show_status","for_id_no"],
  data () {
    return {
      loading: false,
      total_record: 0,
      total_page: 1,
      total_page_suffix: '0',
      rowsPerFetch: 200,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      current_row: 1,
      min_row: 0,
      max_row: 0,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum' },
        { name: 'receiver', align: 'left', label: 'ชื่อผู้รับ', field: 'receiver'},
        { name: 'address1', align: 'left', label: 'ที่อยู่', field: 'address1'},
        { name: 'address2',  align: 'left', label: 'ตำบล/อำเภอ', field: 'address2' },
        { name: 'province',  align: 'left', label: 'จังหวัด', field: 'province' },
        { name: 'zipcode',  align: 'center', label: 'รหัสไปรษณีย์', field: 'zipcode' },
        { name: 'mobile',  align: 'center', label: 'เบอร์มือถือ', field: 'mobile' }
      ],
      /* 
      customDialogModel: false,
      name: ''
      */
      fixed: false
    }
  },
  methods: {
    requestRowClick: function(selected_rownum, selected_row) {
        if (this.selected.rownum !== selected_rownum) {
          this.selected.splice(0,1);
          this.selected.push(selected_row);
          //alert("emit:" +selected_row.rownum);
          this.$emit("onSelected", selected_row);
        }
    },
    fetchDataList: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('for_id_no', self.for_id_no);
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 0,
        url: self.url_api + 'MemberClient/Member.Address.List.JSON.aspx',
        data: params
      })
      .then(function (response) {
        //console.log("response data");
        //console.log("result :" + response.data);
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
       
        self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
        self.total_record = responseDoc.getElementsByTagName('recordcount')[0].textContent;
        //self.total_page = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
        //self.total_page_suffix = "/" + self.total_page;
        self.loading = false;
       
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
      });
    }, 
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //let self = this;
      //console.log('Center Dialog mounted.');
      this.fetchDataList();
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            //alert("up" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)-1);
            if (parseInt(this.current_row) > parseInt(this.min_row)) {
              alert(parseInt(this.current_row)-1);
              this.current_row = parseInt(this.current_row) - 1
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
          case 40:
            //alert("down" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)+1);
            if (parseInt(this.current_row) < parseInt(this.max_row)) {
              alert(parseInt(this.current_row)+1);
              this.current_row = parseInt(this.current_row) + 1;
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
        }
      });
      */
      //this.writeConsole();

      /*
      const self = this;
      console.log('component API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');

      //this.$fetchDataList;
      //this.alertBox();
      */
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      //alert("Show event :" + this.for_id_no);
      this.fixed = this.show_status;
       if (this.show_status) {
        this.fetchDataList();
        //this.selected.splice(0,1);
      }
    },
    for_id_no: function () {
        //alert("param :" + this.for_id_no);
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
  }
 
}
