//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { get } from 'vuex-pathify'
import store from '../store/index'
//import MbscDatePicker from '@/components/MbscDatePicker.vue'

export default {
  components: {
    //MbscDatePicker,
  },
  data () {
    return {
      init: false,
      orders_no:"",
      member_orders_detail_dialog_show: false,
      allregis:false,
      searchText:"",
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
               
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number', align: 'center', label: 'เลขที่เอกสาร', field: 'number'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'refer_id', align: 'center', label: 'ลำดับที่', field: 'refer_id'},
        { name: 'price', align: 'right', label: 'ราคาขาย', field: 'price'},
        { name: 'pv', align: 'right', label: 'PV', field: 'pv'},
        { name: 't_use', align: 'right', label: 'จำนวนค้างแจง', field: 't_use'},
        { name: 'total_price', align: 'right', label: 'รวมเงิน', field: 'total_price' },
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv' }
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      clearFilter: function () {
          this.searchText = '';
          this.getData();
      },
      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
          }
      },
      fetchBonusDate: function () {
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          //let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = self.session_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        params.append('allregis', self.allregis);
        params.append('find_word', self.searchText);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/ProductHoldList.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              self.total_record = record_count;
              self.total_page = page_count;
            } else {
              self.datalist = [];
              self.selected = [];
              self.total_record = 0;
              self.total_page = 1;
            }
            self.loading = false;
            self.init = true;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      //console.log('Component mounted.');
      this.fetchBonusDate();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","รายการสินค้าค้างแจง รหัส : " + this.session_mcode);
      //console.log('Component created.');
      this.$q.loading.show();
  }    
}   
