//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EstoreOrdersDetailDialog",
  props: ["show_status","number"],

  data () {
    return {
      fixed: false,
      estore_orders_detail_dialog_show: false,
      loading: false,
      pagination: {
        rowsPerPage: 0
      },
      query:"1",
      datalist: [],
      selected: [],
      columns: [
        { name: 'id', align: 'center', label: 'รายการ', field: 'id'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'price', align: 'right', label: 'ราคา', field: 'price'},
        { name: 'pv', align: 'right', label: 'PV', field: 'pv' },
        { name: 'quantity', align: 'right', label: 'จำนวน', field: 'quantity' },
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'total_ba' },
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv'}
      ]
    }
  },
  methods: {
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        this.datalist = [];
        this.selected = [];
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.$q.loading.show();
        this.getData(); 
      },
      requestRowClick: function(selected_id, selected_row) {
          //console.log("row click :" + selected_mcode);
          if (this.selected.id !== selected_id) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('number', self.number);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/GetBillHistoryDetail.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            //let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            
            //console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            /*
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            */
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //let self = this;
      //console.log('EStoreOrdersDetail Dialog mounted.');
      /*
      this.$q.loading.show();
      this.mcode_current = this.mcode_start;
      this.getData();
      
      if (this.mcode_start != "") {
        this.mcode_current = this.mcode_start;
        this.$q.loading.show();
        this.getData();
      }*/
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            //alert("up" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)-1);
            if (parseInt(this.current_row) > parseInt(this.min_row)) {
              alert(parseInt(this.current_row)-1);
              this.current_row = parseInt(this.current_row) - 1
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
          case 40:
            //alert("down" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)+1);
            if (parseInt(this.current_row) < parseInt(this.max_row)) {
              alert(parseInt(this.current_row)+1);
              this.current_row = parseInt(this.current_row) + 1;
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
        }
      });
      */
      //this.writeConsole();

      /*
      const self = this;
      console.log('component API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
ss
      */
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created () {
      //console.log('Component created.');
  }
}
