//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EstoreOrdersDetailDialog",
  props: ["show_status","number"],

  data () {
    return {
      ui_size: "",
      fixed: false,
      estore_orders_detail_dialog_show: false,
      loading: false,
      query:"1",
      pagination: {
        rowsPerPage: 0
      },
      datalist: [],
      selected: [],
      columns: [
        { name: 'id', align: 'center', label: 'รายการ', field: 'id'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'price', align: 'right', label: 'ราคา', field: 'price'},
        { name: 'quantity', align: 'right', label: 'จำนวน', field: 'quantity' },
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'total_ba' },
      ],
      columns_mobile: [
        { name: 'prodname', align: 'left', label: 'รายละเอียดสินค้า', field: 'prodname'},
      ],
    }
  },
  methods: {
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        this.datalist = [];
        this.selected = [];
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.$q.loading.show();
        this.getData(); 
      },
      requestRowClick: function(selected_id, selected_row) {
          //console.log("row click :" + selected_mcode);
          if (this.selected.id !== selected_id) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('number', self.number);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/eStore.GetBillHistoryDetail.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      },
      setUISize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.ui_size = "D";
        }
        else {
          //console.log('screen small');
          this.ui_size = "M";
        }
    },
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //console.log('EStoreOrdersDetail Dialog mounted.');
  },
  watch: {
    "$q.screen.width"() {
      this.setUISize();
    },
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      full_width_vw : function () {
        if (this.ui_size == "M") {
          return true
        } else {
          return false
        }
      }
  },
  created () {
      //console.log('Component created.');
      this.setUISize();
  }
}
