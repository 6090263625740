//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'EpointDialog',
  props: ["show_status", "number", "total_amount_net"],
  data () {
    return {
      confirm_dialog_visible: false,
      full_width_boolean: false,
      otp_id: 0,
      mobile_no: "",
      epoint : {
        paid : 0,
        balance: 0
      },
      epoint_paid_readonly: false,
      sendOTPBtnClass: "col-12 hidden",
      OTPClass: "col-12 hidden",
      otp : {
        char1 : "",
        char2 : "",
        char3 : "",
        char4 : "",
        char5 : "",
      },
      loading: false,
      fixed: false,
      dialog_style: "",
    }
  },
  methods: {
    setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 320px; max-width: 40vw;";
        }
        else {
          //console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "height:auto;"
          //this.dialog_style = "height:465px;";
        }
    },
    sendOTP : function() {
      this.epoint_paid_readonly = true;
      //alert("use e-Point : " + this.epoint.paid);
      
      this.$q.loading.show();
      setTimeout(() => {
          //this.ePointSendOTP();
          //this.OTPFormShow();
      }, 1000);
      
    },
    OTPFormShow : function () {
      this.sendOTPBtnClass = "col-12 hidden";
      this.OTPClass = "col-12";
      this.$q.loading.hide();
    },
    OTPFormHide : function () {
      this.OTPClass = "col-12 hidden";
      this.otp.char1 = "";
      this.otp.char2 = "";
      this.otp.char3 = "";
      this.otp.char4 = "";
      this.otp.char5 = "";
      this.epoint_paid_readonly = false;
    },
    confirm_data: function() {
      this.$refs.qDialog.hide();
    },
    ePointSendOTP: function () {
      let self = this;
      self.$q.loading.show();
      const params = new URLSearchParams();
      params.append('number', this.number);
      params.append('epoint', this.epoint.paid);
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 0,
        url: self.url_api + 'MemberClient/ePoint.SendOTP.JSON.aspx',
        data: params
      })
      .then(function (response) {
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        self.otp_id = parseInt(responseDoc.getElementsByTagName('otp_result')[0].textContent);
        let otp_text = responseDoc.getElementsByTagName('otp_text')[0].textContent;
        alert(otp_text);
        if (self.otp_id == 0) {
            self.triggerNegative("ไม่สามารถส่ง OTP ได้");
        }
        self.$q.loading.hide();
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.triggerNegative("Error");
          self.$q.loading.hide();
      });
    },
    ePointGetBalance: function () {
      let self = this;
      self.$q.loading.show();
      const params = new URLSearchParams();
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 0,
        url: self.url_api + 'MemberClient/ePoint.GetBalance.JSON.aspx',
        data: params
      })
      .then(function (response) {
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        self.epoint.balance = responseDoc.getElementsByTagName('epoint')[0].textContent;
        self.mobile_no = responseDoc.getElementsByTagName('mobile')[0].textContent;
        self.$q.loading.hide();
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
      });
    }, 
    ePointValidateOTP: function () {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('number', this.number);
        params.append('epoint', this.epoint.paid);
        params.append('otp', this.otp_value);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },        
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.ValidateOTP.JSON.aspx',
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let update_status = parseInt(responseDoc.getElementsByTagName('update_status')[0].textContent);
          let update_message = responseDoc.getElementsByTagName('update_message')[0].textContent;
          if (update_status == 1) {
            let epoint_paid = parseFloat(self.epoint.paid)
            let total_amount_net = parseFloat(self.replaceAll(self.total_amount_net,",",""));
            if (total_amount_net == epoint_paid) {
                self.$emit("Finish", self.epoint.paid);
            } else {
                self.$emit("Update", self.epoint.paid);
            }
          } else {
            self.triggerNegative(update_message);
          }
          self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("Error");
            self.$q.loading.hide();
        });      
    },
    ePointUse: function () {
        this.confirm_dialog_visible = false; 
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('number', this.number);
        params.append('epoint', this.epoint.paid);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },        
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.Use.JSON.aspx',
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let update_status = parseInt(responseDoc.getElementsByTagName('update_status')[0].textContent);
          let update_message = responseDoc.getElementsByTagName('update_message')[0].textContent;
          if (update_status == 1) {
            let epoint_paid = parseFloat(self.epoint.paid)
            let total_amount_net = parseFloat(self.replaceAll(self.total_amount_net,",",""));
            if (total_amount_net == epoint_paid) {
                self.$emit("Finish", self.epoint.paid);
            } else {
                self.$emit("Update", self.epoint.paid);
            }
          } else {
            self.triggerNegative(update_message);
          }
          self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("Error");
            self.$q.loading.hide();
        });
    },
    /*
     validatePassword: function () {
       if (this.new_password != "") {
          if ((this.confirm_password == this.new_password)) {
              this.updateMemberPassword();
          } else {
            
                if (this.confirm_password != this.new_password) {
                    this.triggerWarning("รหัสผ่านใหม่ ไม่ตรงกับ ยืนยันรหัสผ่าน");
                }
          }
       }
     },
     updateMemberPassword: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('mcode', this.mcode);
      params.append('pwd_new', this.new_password);

      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 10000,
        url: self.url_api + 'MemberClient/MemberChangePassword.JSON.aspx',
        data: params
      })
      .then(function (response) {
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        console.log("result :" + responseDoc.getElementsByTagName('results')[0].textContent);
        self.loading = false;
        self.triggerPositive();
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
          self.triggerNegative();
      });
    }, 
    triggerCustomRegisteredType2 () {
      // this one overrides some of the original
      // options of the "my-notif" registered type
      this.$q.notify({
        type: 'my-notif',
        icon: 'contactless',
        message: `This notification is using a custom type.`,
        caption: `It overrides the type's default icon and color.`,
        color: 'primary',
        progress: true,
        textColor: 'white'
      })
    },
    */
    triggerPositive () {
      this.$q.notify({
        type: 'positive',
        progress: true,
        classes: "app-font",
        message: `แก้ไขรหัสผ่านเรียบร้อยแล้ว`
      });
      //this.$refs.qDialog.hide();
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        classes: "app-font",
        message: msg_text
      })
    },
    triggerWarning (msg_text) {
      this.$q.notify({
        type: 'warning',
        classes: "app-font",
        message: msg_text
      })
    },
    /*
    restrictInput(event) {
      let allowedKeys = [8, 33, 64, 35, 36, 37, 42]
      let key = event.keyCode;
      if ((key >= 48 && key <= 57) || 
          (key >= 65 && key <= 90) || 
          (key >= 97 && key <= 122) || allowedKeys.includes(key)) {
        //console.log("Restrict Pass key:" + key + " value : " + String.fromCharCode(key));
      } else {
        event.preventDefault();
        //console.log("Restrict Not Pass key:" + key + " value : " + String.fromCharCode(key));
      }
    },
    */
    onShow() {
      this.ePointGetBalance();
    },
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      /*
      this.epoint.paid = 0;
      this.otp.char1 = "";
      this.otp.char2 = "";
      this.otp.char3 = "";
      this.otp.char4 = "";
      this.otp.char5 = "";
      */
    }
  },
  mounted () {
      //console.log('MemberPasswordDialog mounted.');
  },
  watch: {
      "$q.screen.width"() {
        this.setDialogSize();
      },
      show_status: function () {
        //console.log("fixed :" + this.fixed);
          this.fixed = this.show_status;
        /*
        this.new_password = null;
        this.confirm_password = null;
        */
      },
      "epoint.paid"() {
        let epoint_balance = parseFloat(this.replaceAll(this.epoint.balance,",",""));
        if (parseFloat(this.epoint.paid) > 0 && epoint_balance >= parseFloat(this.epoint.paid)) {
          this.sendOTPBtnClass = "col-12";
        } else {
          if (parseFloat(this.epoint.paid) < 0) {
            this.epoint.paid = 0;
          }
          this.sendOTPBtnClass = "col-12 hidden";
          this.OTPFormHide();
        }
        let epoint_paid = parseFloat(this.epoint.paid)
        let total_amount_net = parseFloat(this.replaceAll(this.total_amount_net,",",""));
        if (epoint_paid > 0) {
          if (epoint_paid > total_amount_net && epoint_paid <= epoint_balance) {
            this.epoint.paid = total_amount_net;
          } else {
              if ( epoint_paid > epoint_balance ) {
                this.epoint.paid = epoint_balance;
              }
          }
        }
      },
      "otp.char1" : function() {
        //alert(this.otp.char1);
        if (this.otp.char1 != "") {
          this.$refs.otp2.focus();
        } else {
          this.$refs.otp1.focus();
        }
      },
      "otp.char2" : function() {
        if (this.otp.char2 != "") {
          this.$refs.otp3.focus();
        } else {
          this.$refs.otp1.focus();
        }
      },
      "otp.char3" : function() {
        if (this.otp.char3 != "") {
          this.$refs.otp4.focus();
        } else {
          this.$refs.otp2.focus();
        }
      },
      "otp.char4" : function() {
        if (this.otp.char4 != "") {
          this.$refs.otp5.focus();
        } else {
          this.$refs.otp3.focus();
        }
      },
      "otp.char5" : function() {
        if (this.otp.char5 == "") {
          this.$refs.otp4.focus();
        }
      }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      epoint_paid_str : function() {
        return this.formatnumber(parseFloat(this.epoint.paid),2);
      },
      otp_value: function() {
        return this.otp.char1 + this.otp.char2 + this.otp.char3 + this.otp.char4 + this.otp.char5;
      }
  },
  created () {
    this.setDialogSize();
    /**
     * The reason we have this here
     * is that the type needs to be
     * registered before using it.
     *
     * The best place would be a boot file instead
     * of a .vue file, otherwise it'll keep on
     * registering it every time your component
     * gets to be used :)
     */
    /*
    this.$q.notify.registerType('my-notif', {
      icon: 'announcement',
      progress: true,
      color: 'brown',
      textColor: 'white',
      classes: 'glossy'
    })
    */
  }
 
}
