//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import HoldBillDialog from '@/components/HoldBillDialog.vue'
import TranMemberListDialog from '@/components/TranMemberListDialog.vue'
import MbscDatePicker from '@/components/MbscDatePicker.vue'

export default {
  name: "TransferBillDialog",
  props: ["show_status"],
  components: {
    MbscDatePicker,
    HoldBillDialog,
    TranMemberListDialog
  },
  data () {
    return {
      tran_date_editable: false,
      input_editable_color: "grey-2",
      tran_member_list_dialog_show: false,
      hold_bill_dialog_show: false,
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      mcode_current: "",
      loading: false,
      number: "",
      prodall:"1",
      type:"1",
      tran_date: "",
      to_mcode:"",
      to_name_t:"",
      total_ba: 0,
      total_pv: 0,
      xmlProd: "",
      datalist: [],
      selected: [],
      pagination: {
        rowsPerPage: 0
      },
      total_record: 0,
      total_page: 1,
      columns: [
          { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
          //{ name: 'number', align: 'center', label: 'เลขที่บิล', field: 'number'},
          { name: 'prodcode', align: 'center', label: 'รหัส', field: 'prodcode'},
          { name: 'prodname', align: 'left', label: 'สินค้า', field: 'prodname'},
          { name: 'refer_id', align: 'center', label: 'รายการที่', field: 'refer_id'},
          //{ name: 'lprice', align: 'left', label: 'ราคาที่', field: 'lprice'},
          { name: 'price', align: 'right', label: 'ราคาขาย', field: 'price' },
          { name: 'pv', align: 'right', label: 'PV', field: 'pv' },
          { name: 'min_reuse', align: 'right', label: 'จำนวนแจง', field: 'min_reuse' },
          { name: 'total_price', align: 'right', label: 'รวมเงิน', field: 'total_price' },
          { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv' },
          { name: 'total_reuse', align: 'right', label: 'คงเหลือ', field: 'total_reuse' },
        ],             
    }
  },
  methods: {
      OBJtoXML(obj) {
        var xml = '';
        for (var prop in obj) {
          xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
          if (obj[prop] instanceof Array) {
            for (var array in obj[prop]) {
              xml += "<" + prop + ">";
              xml += this.OBJtoXML(new Object(obj[prop][array]));
              xml += "</" + prop + ">";
            }
          } else if (typeof obj[prop] == "object") {
            xml += this.OBJtoXML(new Object(obj[prop]));
          } else {
            xml += obj[prop];
          }
          xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
        }
        xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
        return xml
      },
      /*
      change_tran_qty: function() {
        let total_ba = 0, total_pv = 0;
        this.datalist.forEach(function(obj){
            total_ba += obj["min_reuse"] * obj["price"];
            total_pv += obj["min_reuse"] * obj["pv"];
        });
        this.total_ba = total_ba;
        this.total_pv = total_pv;
      },
      */
      change_tran_qty: function() {
        let sum_ba = 0; 
        let sum_pv = 0;
        let self = this;
        this.datalist.forEach(function(obj){
            if (parseInt(obj["min_reuse"]) > parseInt(obj["total_reuse"])) {
              obj["min_reuse"] = obj["total_reuse"];
            }
            let total_price = parseInt(obj["min_reuse"]) * parseFloat(self.replaceAll(obj["price"].toString(),",",""));
            let total_pv = parseInt(obj["min_reuse"]) * parseFloat(self.replaceAll(obj["pv"].toString(),",",""));
            //alert("total_price:" + total_price.toString());
            obj["total_price"] = self.formatnumber(total_price,2);
            obj["total_pv"] = self.formatnumber(total_pv,2);
            sum_ba += total_price;
            sum_pv += total_pv;
        });
        this.total_ba = sum_ba;
        this.total_pv = sum_pv;
      },
      requestRowClick: function(selected_rownum, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.rownum !== selected_rownum) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "วันที่แจง":
            this.tran_date = e;
            break;
        }
      },
      mcode_selected: function (selected) {
        this.to_mcode = selected.mcode;
        this.fetchMcodeName();
      },
      bill_selected: function (selected) {
        this.number = selected.number;
      },
      fetchItemList: function () {
          var self = this;
          self.loading = true;
          self.rowsPerFetch = self.appRowsPerFetch;
          const params = new URLSearchParams();
          params.append('bill_no', self.number);
          params.append('prodall', self.prodall);
          params.append('from_date', self.from_date);
          params.append('to_date', self.to_date);
          params.append('findword', self.searchText);
          self.axios({
            method: 'post',
            headers: {
              'Authorization': self.jwt_token
            },  
            timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins,
            url: self.url_api + 'MemberClient/Transale.Product.Hold.ItemAll.JSON.aspx',
            data: params
          })
          .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
              //alert("record_count : " + record_count);
              /*
              console.log(responseDoc.getElementsByTagName('json')[0].textContent);
              console.log("record count " + record_count.toString());
              console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
              */
              
              if (record_count > 0) {
                self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
                self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              } else {
                self.datalist = [];
                self.selected = [];
              }
              
              self.total_record = record_count;
              self.loading = false;
              self.$q.loading.hide();
          })
          .catch(function (error) {
              console.log("Error :" + error);
              self.datalist = [];
              self.selected = [];
              self.loading = false;
              self.$q.loading.hide();
          });
      },
      openTranMemberDialog: function() {
        this.tran_member_list_dialog_show = true;
        this.$refs.tran_member_list_dialog_ref.fetchDataList();
      },
      tran_member_list_dialog_hide() {
        this.tran_member_list_dialog_show = false;
      },
      openHoldBillDialog: function() {
        this.hold_bill_dialog_show = true;
        this.$refs.hold_bill_dialog_ref.fetchDataList();
      },
      hold_bill_dialog_hide() {
        this.hold_bill_dialog_show = false;
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
        this.number = "";
        this.type = "1";
        this.tran_date = this.session_date;
        this.to_mcode = "";
        this.to_name_t = "";
        this.total_ba = 0;
        this.total_pv = 0;
        this.fetchItemList();
      },
      paddingMcode: function() {
        if (this.to_mcode.length < this.mcode_length) {
          this.to_mcode = this.paddingCode(this.to_mcode);
        }
        //this.fetchMcodeName();
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.to_mcode = "";
        this.to_name_t = "";
        /*
        this.mcode_current = this.mcode_start;
        if (this.mcode_start != "") {
          this.$q.loading.show();
          this.getData(); 
        }*/
      },
      
      fetchMcodeName: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('bill_mcode', self.session_mcode);
        params.append('mcode', self.to_mcode);
        params.append('agent', self.session_agent);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Transale.GetMcodeInfo.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let record_count = responseDoc.getElementsByTagName('recordcount')[0].textContent;
          if (record_count > 0) {
            self.to_name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          } else {
            self.triggerNegative("ไม่พบข้อมูล (พ้นสภาพ หรือ ขาดการติดต่อ)");
          }
          //console.log("to_name_t : " + self.to_name_t);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      /*
      fetchPosition: function () {
        console.log("fetch position");
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/Position.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.positionList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          //self.position_selected = self.positionList.slice(0,1);
          self.position_selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent)[0];
          console.log("fetch position value:  => " + self.position_selected.value);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      editMode() {
        console.log("click");
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_bonus_date = e;
            break;
          case "ถึงวันที่":
            this.to_bonus_date = e;
            break;  
        }
      },
      */
      confirm_postData: function () {
        if (this.to_mcode.length < this.mcode_length || this.to_name_t == "" || this.number.length < 12 || this.total_pv + this.total_ba == 0) {
            this.triggerNegative("ข้อมูลไม่ครบ ตรวจสอบการป้อนข้อมูลอีกครั้ง");
        } else {
                let xml = this.replaceAll(this.replaceAll( this.OBJtoXML(this.datalist),"<rownum>","<tran_item><rownum>"),"</total_pv>","</total_pv></tran_item>"); 
                this.xmlProd = this.replaceAll(xml,"<","#<#");
                //alert("XML:" + this.xmlProd);
                let self = this;
                let type_desc;
                switch (self.type) {
                  case "1":
                    type_desc = "แผน A";
                    break;
                  case "2":
                    type_desc = "แผน B";
                    break;
                }
                this.$q.dialog({
                    title: "ยืนยันการแจงยอด",
                    message: "กดปุ่ม [ตกลง] เพื่อทำการบันทึกข้อมูล</br>ประเภทการแจง : <b>" + type_desc + "</b></br>แจงให้ : <b>" + self.to_mcode + " - " + self.to_name_t + "</b></br>ยอดแจง : <b>"  + self.total_pv_label + " PV, "+ self.total_ba_label + " บาท</b>",
                    persistent: true,
                    html: true,
                    class: "app-font",
                    style: "background-color:#FFFFFF; color:#000000;",
                    ok: {
                      label: "ตกลง",
                      color: 'positive'
                    },
                    cancel: {
                      label: "ยกเลิก",
                      color: "negative"
                    },
                  }).onOk(() => {
                    this.postData();
                    // console.log('>>>> OK')
                  }).onOk(() => {
                    // console.log('>>>> second OK catcher')
                  }).onCancel(() => {
                    // console.log('>>>> Cancel')
                  }).onDismiss(() => {
                    // console.log('I am triggered on both OK and Cancel')
                });
        }
      },
      postData: function () {
        var self = this;
        self.submit_btn_disable = true;
        self.loading = true;
        const params = new URLSearchParams();
        params.append('branch_code', "IN");
        params.append('type', self.type);
        params.append('refer', self.number);
        params.append('mtcode', self.session_mcode);
        params.append('mcode', self.to_mcode);
        params.append('date', self.tran_date);
        params.append('officecontrol', self.office_control_user);
        params.append('xmlProd', self.xmlProd);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },   
          timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/Transale.InsertItem.Temp.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
           /*
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            let pvteam = parseInt(responseDoc.getElementsByTagName('pvteam')[0].textContent);
            let pvcompleteteam = parseInt(responseDoc.getElementsByTagName('pvcompleteteam')[0].textContent);
           
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
          

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
              */
            let result = responseDoc.getElementsByTagName('status')[0].textContent;
            let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
            if (result == "True") {
              let number =  responseDoc.getElementsByTagName('number')[0].textContent;
              result_txt = result_txt + " เลขที่ใบแจง : " + number;
              self.triggerPositive(result_txt);
              self.clearForm();
              self.$parent.getData();
            } else {
              self.triggerNegative(result_txt);
            }

            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        });
      }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
    // เริ่มต้นด้วย tran_date เป็น "" กรณีสมาชิกบันทึก ระบบจะบันทึกวันที่ตามจริงที่ Server ไม่ได้รับจาก input
    //this.tran_date = this.session_date;
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      if (this.office_control_user != "") {
        this.tran_date_editable = true;
      } else {
        this.tran_date_editable = false;
      }
    },
    to_mcode: function () {
      if (this.to_mcode.length == this.mcode_length) {
          this.fetchMcodeName();
      } else {
          this.to_name_t = "";
      }
    },
    number: function () {
      if (this.number_isValid) {
        this.fetchItemList();
      }
    },
    /*
    datalist: function () {
      console.log(this.datalist);
    }
    mcode_start: function () {
      this.mcode_current = this.mcode_start;
      if (this.mcode_start != "") {
        this.$q.loading.show();
        this.getData(); 
      }
    }*/
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      session_agent: get('SESSION_AGENT'),
      office_control_user: get('OFFICE_CONTROL_USER'),
      number_isValid() {
        if (this.number.length == 12) {
          return true;
        } else {
          return false;
        }
      },
      total_ba_label() {
        return this.formatnumber(this.total_ba,2);
      },
      total_pv_label() {
        return this.formatnumber(this.total_pv,2);
      }
  },
  created () {
      this.tran_date = this.session_date;
      this.$q.loading.show();
      //console.log('Component created.');
      //this.mcode_current = this.mcode_start;
      //this.fetchPosition();
  }    
 
}
