//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import TranMemberListDialog from '@/components/TranMemberListDialog.vue'

export default {
  components: {
    MbscDatePicker,
    TranMemberListDialog
  },
  data () {
    return {
      init: false,
      tran_member_list_dialog_show: false,
      to_mcode:"",
      to_name_t:"",
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record_1: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist_1: [],
      selected_1: [],
      datalist_2: [],
      selected_2: [],
      columns_1: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number', align: 'center', label: 'เลขที่ใบแจง', field: 'number'},
        { name: 'type', align: 'center', label: 'ประเภท', field: 'type'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        { name: 'refer', align: 'center', label: 'อ้างเลขที่ใบ Hold', field: 'refer'},
        { name: 'mcode', align: 'center', label: 'รหัสรับแจง', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อ-นามสกุล', field: 'name_t'},
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv'},
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'total_ba'}
      ],
      columns_2: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'รายละเอียด', field: 'prodname'},
        { name: 'unit_name', align: 'center', label: 'หน่วย', field: 'unit_name'},
        { name: 'qty', align: 'right', label: 'จำนวน', field: 'qty'},
        { name: 'total_pv', align: 'right', label: 'PV', field: 'total_pv'},
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'total_ba'}
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      exportPDF_1: function() {
          let self = this;
          openURL(self.url_api + '../pdf/Report.Tran.History.Bill.ByDate.PDF.aspx');    
      },
      exportPDF_2: function() {
          let self = this;
          openURL(self.url_api + '../pdf/Report.Tran.History.Prod.ByDate.PDF.aspx');    
      },
      paddingMcode: function() {
        if (this.to_mcode.length < this.mcode_length) {
          this.to_mcode = this.paddingCode(this.to_mcode);
        }
      },
      mcode_selected: function (selected) {
        this.to_mcode = selected.mcode;
        this.fetchMcodeName();
      },
      openTranMemberDialog: function() {
        this.tran_member_list_dialog_show = true;
        this.$refs.tran_member_list_dialog_ref.fetchDataList();
      },
      tran_member_list_dialog_hide() {
        this.tran_member_list_dialog_show = false;
      },
      fetchMcodeName: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('bill_mcode', self.session_mcode);
        params.append('mcode', self.to_mcode);
        params.append('agent', self.session_agent);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Transale.GetMcodeInfo.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let record_count = responseDoc.getElementsByTagName('recordcount')[0].textContent;
          if (record_count > 0) {
            self.to_name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
            if (self.init) {
              self.getData();
            }
          } else {
            self.triggerNegative("ไม่พบข้อมูล (พ้นสภาพ หรือ ขาดการติดต่อ)");
            self.to_name_t = "";
          }
          //console.log("to_name_t : " + self.to_name_t);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        //console.log('getData');
        if (this.to_mcode.length < this.mcode_length && this.to_name_t != "") {
          return
        }
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('mcode', self.to_mcode);
        /*
        params.append('type_desc', self.type_filter.label);
        */
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/Report.Tran.History.Bill.ByDate.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + responseDoc.getElementsByTagName('recordcount')[0].textContent);
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */
            if (record_count > 0) {
              self.datalist_1 = JSON.parse(responseDoc.getElementsByTagName('json1')[0].textContent);
              self.selected_1 = JSON.parse(responseDoc.getElementsByTagName('json1')[0].textContent).slice(0, 1);
              self.datalist_2 = JSON.parse(responseDoc.getElementsByTagName('json2')[0].textContent);
              self.selected_2 = JSON.parse(responseDoc.getElementsByTagName('json2')[0].textContent).slice(0, 1);
              console.log(self.datalist_2);
            } else {
              self.datalist_1 = [];
              self.selected_1 = [];
              self.datalist_2 = [];
              self.selected_2 = [];
            }
            self.total_record_1 = record_count;
            self.total_page = page_count;
            self.loading = false;
            setTimeout(() => {
               self.$q.loading.hide();
               self.init = true;
            }, 2000);
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {     
      //console.log('Component mounted.');
      this.to_mcode = "";
      this.to_name_t = "";
      this.from_date = this.session_mdate;
      this.to_date = this.session_date;
      this.getData();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_name_t: get('SESSION_MCODE_NAME'),
      session_mdate: get('SESSION_MDATE'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      this.getData();
    },
    to_mcode: function () {
      if (this.to_mcode.length == this.mcode_length) {
          this.fetchMcodeName();
      } else {
          this.to_name_t = "";
      }
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","รายงานการแจงยอด รหัส : " + this.session_mcode);
      //console.log('Component created.');
      this.$q.loading.show();
  }    
}   
