//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import MemberOrdersDetailDialog from '@/components/MemberOrdersHistoryDetailDialog.vue'
import TransferBillDialog from '@/components/TransferBillDialog.vue'

export default {
  components: {
    MbscDatePicker,
    MemberOrdersDetailDialog,
    TransferBillDialog
  },
  data () {
    return {
      init: false,
      orders_no:"",
      transfer_bill_dialog_show: false,
      member_orders_detail_dialog_show: false,
      searchText:"",
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
  
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number', align: 'center', label: 'เลขที่ใบแจง', field: 'number'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        { name: 'refer_number', align: 'center', label: 'เลขที่ใบ Hold', field: 'refer_number'},
        { name: 'mcode', align: 'center', label: 'รหัสผู้รับแจง', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อผู้รับแจง', field: 'name_t'},
        { name: 'total_pv', align: 'right', label: 'PV', field: 'total_pv' },
        { name: 'total_ba', align: 'right', label: 'จำนวนเงิน', field: 'total_ba' },
        { name: 'type', align: 'center', label: 'สถานะ', field: 'type' }
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      openTransferDialog: function() {
        this.transfer_bill_dialog_show = true;
      },
      clearFilter: function()  {
        this.searchText = '';
        this.getData();
      },
      exportPDF: function(number) {
          let self = this;
          let actionUrl = self.url_api + "../pdf/Transale.Print.JSON.PDF.aspx";
          let windowName = "ใบแจงยอดเลขที่ : " + number;
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        number: number
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },
      openDetailDialog: function(number) {
        this.orders_no = number;
        this.member_orders_detail_dialog_show = true;
      },
      transfer_bill_dialog_hide () {
        this.transfer_bill_dialog_show = false;
      },
      member_orders_detail_dialog_hide () {
        this.member_orders_detail_dialog_show = false;
        //console.log("hide set member_orders_detail_dialog_show = " + this.member_orders_detail_dialog_show);
      },
      openDeliverInfo: function( deliver_code, deliver_url ) {
        openURL(deliver_url + deliver_code);
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      //Bonus/Report.Daily.Bonus.Getlastdate.aspx
      fetchBonusDate: function () {
        //console.log("fetch bonus date");
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);

        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          //let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = self.session_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      editMode() {
        //console.log("click");
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('findword', self.searchText);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0, // Let's say you want to wait at least unlimit mins,
          url: self.url_api + 'MemberClient/GetTranHistory.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            setTimeout(() => {
              self.$q.loading.hide();
              self.init = true;
            }, 4000);
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
    this.from_date = this.session_mdate;
    this.to_date = this.session_date;
    this.getData();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mdate: get('SESSION_MDATE'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }  
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ข้อมูลการแจงยอด รหัส : " + this.session_mcode);
      //console.log('Component created.');
      this.$q.loading.show();
      //this.fetchBonusDate();
  }    
}   
